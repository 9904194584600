export enum PropertyCountry {
  CA = 'CA',
  CN = 'CN',
  HK = 'HK',
  ID = 'ID',
  JP = 'JP',
  KR = 'KR',
  MY = 'MY',
  PH = 'PH',
  SG = 'SG',
  TH = 'TH',
  TW = 'TW',
  US = 'US',
  VN = 'VN',
}
