import dayjs from 'dayjs';

export function formatDate(date: Date): string {
  return dayjs(date).format('YYYY-MM-DD');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatNull(value: any | null): string {
  if (value === null) {
    return '-';
  } else {
    return value;
  }
}

export function localeLabel(value: string): string {
  switch (value) {
    case 'ko-KR':
      return 'KO';
    case 'en-US':
      return 'EN';
    default:
      return value;
  }
}

export function requiredValidationStatus(value: string): string {
  return value ? '' : 'error';
}

export function requiredValidationMessage(value: string): string {
  return value ? '' : '필수 입력값입니다.';
}
