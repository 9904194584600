import dayjs from 'dayjs';
import 'dayjs/locale/ko.js';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

const dayjsPlugin = {
  install: () => {
    dayjs.locale('ko');
    dayjs.extend(isSameOrBefore);
  },
};

export default dayjsPlugin;
