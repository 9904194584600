import { ISelectOption } from '@/types/common';
import { BookingEngineType } from '../enums/booking-engine-type.enum';

export const bookingEngineTypeOptions: ISelectOption[] = [
  { label: 'Standalone', value: BookingEngineType.standalone },
  { label: 'Embedded', value: BookingEngineType.embedded },
];

export const bookingEngineLocaleOptions: ISelectOption[] = [
  { label: 'KO', value: 'ko-KR' },
  { label: 'EN', value: 'en-US' },
];
