import { defineStore } from 'pinia';

import { IBookingEngineApplicationState } from '@/types/state';
import { defaultBookingEngineProperty, IBookingEngineProperty } from '@/types/booking-engine-property';
import { IGoogleMapsUrlConfirm, defaultGoogleMapsUrlConfirm } from '@/types/google-maps';

export const useBookingEngineApplicationStore = defineStore('booking-engine-application', {
  state: (): IBookingEngineApplicationState => ({
    bookingEngineProperty: { ...defaultBookingEngineProperty },
    isPropertyValid: false,
    googleMapsUrlConfirm: { ...defaultGoogleMapsUrlConfirm },
  }),
  getters: {
    isFillingIn(state: IBookingEngineApplicationState): boolean {
      return !!state.bookingEngineProperty.id;
    },

    applicationProperty(state: IBookingEngineApplicationState): IBookingEngineProperty {
      return state.bookingEngineProperty;
    },

    isApplicationPropertyValid(state: IBookingEngineApplicationState): boolean {
      return state.isPropertyValid;
    },

    applicationGoogleMaps(state: IBookingEngineApplicationState): IGoogleMapsUrlConfirm {
      return state.googleMapsUrlConfirm;
    },
  },
  actions: {
    setApplicationProperty(bookingEngineProperty: IBookingEngineProperty): void {
      this.bookingEngineProperty = bookingEngineProperty;
    },

    setIsApplicationPropertyValid(isValid: boolean): void {
      this.isPropertyValid = isValid;
    },

    setApplicationGoogleMaps(googleMapsUrlConfirm: IGoogleMapsUrlConfirm): void {
      this.googleMapsUrlConfirm = googleMapsUrlConfirm;
    },

    resetApplication(): void {
      this.bookingEngineProperty = { ...defaultBookingEngineProperty };
      this.isPropertyValid = false;
      this.googleMapsUrlConfirm = { ...defaultGoogleMapsUrlConfirm };
    },
  },
});
