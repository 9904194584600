import { GoogleMapsMatchStatus } from '@/types/google-hotel';
import { BookingEngineOnboardingStatus, BookingEngineType, PropertyProduct } from '../types/booking-engine-property';

export function isLiveOnGoogleLabel(isLiveOnGoogle: boolean): string {
  return isLiveOnGoogle ? 'ON' : 'OFF';
}

export function isPropertyEnabledLabel(isEnabled: boolean): string {
  return isEnabled ? 'ON' : 'OFF';
}

export function isPropertyEnabledType(isEnabled: boolean): string {
  return isEnabled ? 'success' : 'error';
}

export function propertyProductLabel(product: PropertyProduct): string {
  switch (product) {
    case PropertyProduct.ezsell:
      return 'Easysell';
    case PropertyProduct.plus:
      return 'Plus';
    case PropertyProduct.wave:
      return 'Wave';
    case PropertyProduct.api:
      return 'API';
    default:
      return '-';
  }
}

export function bookingEngineOnboardingStatusLabel(status: BookingEngineOnboardingStatus): string {
  switch (status) {
    case BookingEngineOnboardingStatus.created:
      return '오픈 대기';
    case BookingEngineOnboardingStatus.awaiting:
      return '검수중';
    case BookingEngineOnboardingStatus.approved:
      return '오픈 완료';
    default:
      return '-';
  }
}

export function bookingEngineOnboardingStatusType(status: BookingEngineOnboardingStatus): string {
  switch (status) {
    case BookingEngineOnboardingStatus.created:
    default:
      return '';
    case BookingEngineOnboardingStatus.awaiting:
      return 'info';
    case BookingEngineOnboardingStatus.approved:
      return 'success';
  }
}

export function bookingEngineTypeLabel(type: BookingEngineType): string {
  switch (type) {
    case BookingEngineType.standalone:
      return '홈페이지형';
    case BookingEngineType.embedded:
      return '예약관리형';
    default:
      return '-';
  }
}

export function googleMapsMatchStatusLabel(googleMapsMatchStatus?: GoogleMapsMatchStatus): string {
  switch (googleMapsMatchStatus) {
    case GoogleMapsMatchStatus.notMatched:
    case GoogleMapsMatchStatus.mapOverlap:
    case GoogleMapsMatchStatus.matchStatusUnspecified:
    case GoogleMapsMatchStatus.matchStatusUnknown:
      return 'NOT';
    case GoogleMapsMatchStatus.matched:
      return 'OK';
    default:
      return '-';
  }
}

export function googleMapsMatchStatusType(googleMapsMatchStatus?: GoogleMapsMatchStatus): string {
  switch (googleMapsMatchStatus) {
    case GoogleMapsMatchStatus.notMatched:
    case GoogleMapsMatchStatus.mapOverlap:
    case GoogleMapsMatchStatus.matchStatusUnspecified:
    case GoogleMapsMatchStatus.matchStatusUnknown:
      return 'error';
    case GoogleMapsMatchStatus.matched:
      return 'success';
    default:
      return '';
  }
}

export function googleMapsMatchStatusPopoverLabel(googleMapsMatchStatus?: GoogleMapsMatchStatus): string {
  switch (googleMapsMatchStatus) {
    case GoogleMapsMatchStatus.notMatched:
      return '구글지도에 등록된 숙소 정보가 일치하지 않습니다.';
    case GoogleMapsMatchStatus.mapOverlap:
      return '현 주소에 2개 이상의 숙소가 구글지도에 등록되어 있습니다.';
    case GoogleMapsMatchStatus.matchStatusUnspecified:
    case GoogleMapsMatchStatus.matchStatusUnknown:
      return '구글지도에 숙소가 등록되어 있지 않습니다.';
    case GoogleMapsMatchStatus.matched:
    default:
      return '';
  }
}
