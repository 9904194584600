import { defineStore } from 'pinia';

import { IUserState } from '@/types/state';
import { IUser, UserRole } from '@/types/user';

import { useBookingEngineApplicationStore } from './booking-engine-application';

export const useUserStore = defineStore('user', {
  state: (): IUserState => ({
    user: null,
  }),
  getters: {
    isLoggedIn(state: IUserState): boolean {
      return !!state.user && !!state.user.id;
    },
    loggedInUser(state: IUserState): IUser | null {
      return state.user;
    },
    userId(state: IUserState): number {
      return state.user?.id || 0;
    },
    adminAuthorized(state: IUserState): boolean {
      return !!state.user && state.user.role === UserRole.admin;
    },
    managerAuthorized(state: IUserState): boolean {
      return !!state.user && [UserRole.admin, UserRole.manager].includes(state.user.role as UserRole);
    },
  },
  actions: {
    initUser(): void {
      const storedUserId = localStorage.getItem('user_id');
      if (storedUserId) {
        this.user = {
          id: +storedUserId,
          name: localStorage.getItem('user_name') as string,
          email: localStorage.getItem('user_email') as string,
          role: localStorage.getItem('user_role') as UserRole,
        };
      }
    },

    login(user: IUser): void {
      this.user = user;

      localStorage.setItem('user_id', user.id.toString());
      localStorage.setItem('user_name', user.name);
      localStorage.setItem('user_email', user.email);
      localStorage.setItem('user_role', user.role);
    },
    logout(): void {
      this.user = null;

      const applicationStore = useBookingEngineApplicationStore();
      applicationStore.resetApplication();

      localStorage.removeItem('user_id');
      localStorage.removeItem('user_name');
      localStorage.removeItem('user_email');
      localStorage.removeItem('user_role');
    },
  },
});
