import { UserRole } from '../enums/user-role.enum';
import { IUserRoleInfo } from '../interfaces/user-role-info.interface';

export const userRoleInfos: IUserRoleInfo[] = [
  { role: UserRole.admin, label: '관리자', description: '전체 메뉴 접근 가능, 전체 수정 가능' },
  {
    role: UserRole.manager,
    label: '운영자',
    description: 'Booking Engine 등록 접근 가능, 숙소리스트의 url 정보를 제외한 나머지 수정 가능',
  },
  { role: UserRole.member, label: '일반 계정', description: '숙소 리스트 접근 가능, 보기 권한' },
];
