const Login = (): Promise<typeof import('*.vue')> => import(/* webpackChunkName: "login" */ './LoginView.vue');
const BookingEnginePropertyList = (): Promise<typeof import('*.vue')> =>
  import(/* webpackChunkName: "booking-engine-property-list" */ './BookingEnginePropertyListView.vue');
const BookingEnginePropertyDetail = (): Promise<typeof import('*.vue')> =>
  import(/* webpackChunkName: "booking-engine-property-detail" */ './BookingEnginePropertyDetailView.vue');
const BookingEngineApplication = (): Promise<typeof import('*.vue')> =>
  import(/* webpackChunkName: "booking-engine-application" */ './BookingEngineApplicationView.vue');
const AdminApplicationList = (): Promise<typeof import('*.vue')> =>
  import(/* webpackChunkName: "admin-application-list" */ './AdminApplicationListView.vue');
const AdminGoogleHotelList = (): Promise<typeof import('*.vue')> =>
  import(/* webpackChunkName: "admin-google-hotel-list" */ './AdminGoogleHotelListView.vue');

export {
  Login,
  BookingEnginePropertyList,
  BookingEnginePropertyDetail,
  BookingEngineApplication,
  AdminApplicationList,
  AdminGoogleHotelList,
};
