import { GoogleMapsError } from '@/types/google-maps';

export function googleMapsErrorMessage(googleMapsError: GoogleMapsError | null): string {
  switch (googleMapsError) {
    case GoogleMapsError.noResults:
      return '구글 지도 내 해당 숙소가 등록되어 있지 않습니다.';
    case GoogleMapsError.addressMismatch:
      return '구글 지도 내 해당 숙소가 다른 주소로 등록되어 있습니다.';
    case GoogleMapsError.nameMismatch:
      return '구글 지도 내 해당 주소에 다른 이름의 숙소가 등록되어 있습니다.';
    case GoogleMapsError.urlConfirmFail:
      return '입력하신 구글 지도 URL은 숙소 정보와 일치하지 않습니다.';
    default:
      return '';
  }
}
