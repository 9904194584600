import { PropertyProduct } from '../enums/property-product.enum';
import { BookingEngineType } from '../enums/booking-engine-type.enum';
import { BookingEngineTheme } from '../enums/booking-engine-theme.enum';
import { BookingEngineOnboardingStatus } from '../enums/booking-engine-onboarding-status.enum';
import { PropertyCountry } from '../enums/property-country.enum';

import { IProperty } from '../interfaces/property.interface';
import { IBookingEngineSettings } from '../interfaces/booking-engine-settings.interface';
import { IBookingEngineProperty } from '../interfaces/booking-engine-property.interface';

import { defaultGoogleHotelOnboardingRequest } from '@/types/google-hotel';

export const defaultProperty: IProperty = {
  vendor: {
    id: 0,
    name: '',
  },
  product: PropertyProduct.ezsell,
  isEnabled: true,
  name: '',
  address: '',
};

export const defaultBookingEngineSettings: IBookingEngineSettings = {
  urlPath: '',
  type: BookingEngineType.standalone,
  theme: BookingEngineTheme.ondaBlue,
  useLightTheme: true,
  businessRegistrationNumber: '',
  defaultLocale: 'ko-KR',
  activeLocales: ['ko-KR'],
  localizedContents: [
    {
      locale: 'ko-KR',
      companyName: '',
      legalBusinessRepresentative: '',
      businessAddress: '',
    },
  ],
};

export const defaultBookingEngineProperty: IBookingEngineProperty = {
  id: 0,
  gdsId: null,
  ospId: null,
  bookingEngineOnboardingStatus: BookingEngineOnboardingStatus.created,
  isGoogleHotelsListing: false,
  isLiveOnGoogle: false,
  googleMapsUrl: null,
  country: PropertyCountry.KR,
  timezone: 'Asia/Seoul',
  currency: '',
  property: { ...defaultProperty },
  settings: { ...defaultBookingEngineSettings },
  googleHotelOnboardingRequest: { ...defaultGoogleHotelOnboardingRequest },
  memos: [],
  histories: [],
};
