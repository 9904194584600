import { UserRole } from '@/types/user';

export function userRoleLabel(role?: UserRole | null): string {
  switch (role) {
    case UserRole.admin:
      return '관리자';
    case UserRole.manager:
      return '운영자';
    case UserRole.member:
      return '일반 계정';
    default:
      return '-';
  }
}
