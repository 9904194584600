import { IFilter } from '@/types/common';
import { GoogleHotelOnboardingErrorType, GoogleHotelOnboardingStatus } from '@/types/google-hotel';
import { BookingEngineOnboardingStatus } from '../enums/booking-engine-onboarding-status.enum';
import { PropertyFilterType } from '../enums/property-filter-type.enum';

export const propertyFilters: IFilter[] = [
  // Hide product filter and vendor filter until API is implemented (TODO later)
  // {
  //   label: '사용 제품',
  //   key: PropertyFilterType.product,
  //   options: [
  //     { label: 'Easysell', value: PropertyProduct.ezsell, isSelected: true },
  //     { label: 'Plus', value: PropertyProduct.plus, isSelected: true },
  //     { label: 'Wave', value: PropertyProduct.wave, isSelected: true },
  //     { label: 'API', value: PropertyProduct.api, isSelected: true },
  //   ],
  //   isLarge: false,
  //   isDisabled: false,
  //   useMultiselect: true,
  //   placeholder: '선택해주세요',
  // },
  // {
  //   label: '공급사',
  //   key: PropertyFilterType.vendor,
  //   options: [],
  //   isLarge: false,
  //   isDisabled: false,
  //   useMultiselect: true,
  //   placeholder: '선택해주세요',
  // },
  {
    label: 'BE 온보딩 상태',
    key: PropertyFilterType.bookingEngineOnboardingStatus,
    options: [
      { label: '오픈 대기', value: BookingEngineOnboardingStatus.created, isSelected: true },
      { label: '검수중', value: BookingEngineOnboardingStatus.awaiting, isSelected: true },
      { label: '오픈 완료', value: BookingEngineOnboardingStatus.approved, isSelected: true },
    ],
    isLarge: false,
    isDisabled: false,
    useMultiselect: true,
    placeholder: '선택해주세요',
  },
  {
    label: 'GH 온보딩 상태',
    key: PropertyFilterType.googleHotelOnboardingStatus,
    options: [
      // Hide null option from GoogleHotelOnboardingStatus filter until API is implemented (TODO later)
      // { label: '-', value: null, isSelected: true },
      {
        label: '대기중',
        value: GoogleHotelOnboardingStatus.started,
        isSelected: true,
      },
      {
        label: '진행중',
        value: GoogleHotelOnboardingStatus.inProgress,
        isSelected: true,
      },
      { label: '완료', value: GoogleHotelOnboardingStatus.completed, isSelected: true },
      { label: '오류', value: GoogleHotelOnboardingStatus.errorOccurred, isSelected: true },
    ],
    isLarge: false,
    isDisabled: false,
    useMultiselect: true,
    placeholder: '선택해주세요',
  },
  {
    label: '상태 내용',
    key: PropertyFilterType.googleHotelOnboardingErrorType,
    options: [
      { label: '-', value: null, isSelected: true },
      { label: '요금/재고 없음 (1년 전송)', value: GoogleHotelOnboardingErrorType.ariLongTerm, isSelected: true },
      { label: '요금/재고 없음 (기간 설정)', value: GoogleHotelOnboardingErrorType.ariShortTerm, isSelected: true },
      {
        label: '구글 fetch 후 요금/재고 재 전송',
        value: GoogleHotelOnboardingErrorType.unlisted,
        isSelected: true,
      },
    ],
    isLarge: true,
    isDisabled: false,
    useMultiselect: true,
    placeholder: '선택해주세요',
  },
];
