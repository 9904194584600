import { App } from 'vue';
import axios from 'axios';

const axiosPlugin = {
  install: (app: App) => {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    app.provide('$axios', axios);
  },
};

export default axiosPlugin;
