import { ref } from 'vue';

import { defaultPageSize, IPagination } from '@/types/common';

export function usePagination() {
  const pagination = ref<IPagination>({
    page: 1,
    pageSize: defaultPageSize,
    itemCount: 0,
    onChange: (page: number): void => {
      pagination.value.page = page;
    },
    prefix(): string {
      const start = (pagination.value.page - 1) * pagination.value.pageSize + 1;
      const end = start + pagination.value.pageSize - 1;
      return pagination.value.itemCount > 0
        ? `${start} - ${Math.min(end, pagination.value.itemCount)} / ${pagination.value.itemCount}`
        : '';
    },
  });

  function setTotal(total: number): void {
    pagination.value.itemCount = total;
  }

  function changePageSize(pageSize: number): void {
    pagination.value.pageSize = pageSize;
    resetPage();
  }

  function resetPage(): void {
    pagination.value.page = 1;
  }

  return { pagination, changePageSize, resetPage, setTotal };
}
