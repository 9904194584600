export enum ExceptionCode {
  // Booking Engine Exceptions
  PROPERTY_NOT_FOUND = 1200,
  APPLICATION_ALREADY_SUBMITTED = 1310,
  ALREADY_MAPPED = 1312,

  // Google Maps Exceptions
  NO_RESULTS = 4000,
  ADDRESS_MISMATCH = 4001,
  NAME_MISMATCH = 4002,
}
