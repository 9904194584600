import { App } from 'vue';

import * as filters from '@/filters';

declare module 'vue' {
  interface ComponentCustomProperties {
    $filters: typeof filters;
  }
}

const filtersPlugin = {
  install: (app: App) => {
    app.config.globalProperties.$filters = {
      ...filters,
    };
  },
};

export default filtersPlugin;
