export enum BookingEngineTheme {
  white = 'white',
  darkGray = 'darkGray',
  brick = 'brick',
  brown = 'brown',
  beige = 'beige',
  mustard = 'mustard',
  green = 'green',
  ondaBlue = 'ondaBlue',
  midnight = 'midnight',
}
