import { IGoogleHotelOnboardingRequest } from '../interfaces/google-hotel-onboarding-request.interface';

export const defaultGoogleHotelOnboardingRequest: IGoogleHotelOnboardingRequest = {
  gdsPropertyId: 0,
  ospId: 0,
  status: null,
  errorType: null,
  createdAt: null,
  startAt: null,
  endAt: null,
  errorTermStartAt: null,
  errorTermEndAt: null,
};
