import { VNode } from '@vue/runtime-core';

import dayjs from 'dayjs';
import { h } from 'vue';

import {
  GoogleHotelOnboardingErrorType,
  GoogleHotelOnboardingStatus,
  GoogleHotelPushType,
  IGoogleHotelOnboardingRequest,
} from '@/types/google-hotel';

export function googleHotelOnboardingStatusLabel(status: GoogleHotelOnboardingStatus | null): string {
  switch (status) {
    case GoogleHotelOnboardingStatus.started:
      return '대기중';
    case GoogleHotelOnboardingStatus.inProgress:
      return '진행중';
    case GoogleHotelOnboardingStatus.errorOccurred:
      return '오류';
    case GoogleHotelOnboardingStatus.completed:
      return '오픈 완료';
    default:
      return '-';
  }
}

export function googleHotelOnboardingStatusType(status: GoogleHotelOnboardingStatus | null): string {
  switch (status) {
    case GoogleHotelOnboardingStatus.started:
    case GoogleHotelOnboardingStatus.inProgress:
      return 'info';
    case GoogleHotelOnboardingStatus.errorOccurred:
      return 'error';
    case GoogleHotelOnboardingStatus.completed:
      return 'success';
    default:
      return '';
  }
}

export function googleHotelErrorMessage(
  errorType: GoogleHotelOnboardingErrorType | null,
  onboardingRequest: IGoogleHotelOnboardingRequest,
): VNode {
  let label1 = '-';
  let label2 = '';
  if (onboardingRequest.errorType === GoogleHotelOnboardingErrorType.unlisted) {
    label1 = '구글 fetch 후 요금/재고 재 전송';
  } else if (
    onboardingRequest.startAt &&
    onboardingRequest.endAt &&
    onboardingRequest.errorTermStartAt &&
    onboardingRequest.errorTermEndAt
  ) {
    const errorStart = dayjs(onboardingRequest.errorTermStartAt).format('YYYY-MM-DD');
    const errorEnd = dayjs(onboardingRequest.errorTermEndAt).format('YYYY-MM-DD');
    const timeWindow =
      onboardingRequest.errorType === GoogleHotelOnboardingErrorType.ariShortTerm ? '기간 설정' : '1년 전송';

    label1 = `[${errorStart}~${errorEnd}]`;
    label2 = `요금/재고 없음(${timeWindow})`;
  }

  return h('div', [h('p', label1), h('p', label2)]);
}

export function googleHotelPushTypeLabel(pushType: GoogleHotelPushType | null): string {
  switch (pushType) {
    case GoogleHotelPushType.rates:
      return '요금';
    case GoogleHotelPushType.inventories:
      return '재고';
    case GoogleHotelPushType.ratesAndInventories:
      return '요금 & 재고';
    default:
      return '-';
  }
}
