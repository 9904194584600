import { Component, h, VNode } from 'vue';

import { RouterLink } from 'vue-router';
import { NIcon, NSwitch, NTag, NPopover } from 'naive-ui';

export function useVNode() {
  function renderIcon(icon: Component, classes?: string[]): () => VNode {
    return () => h(NIcon, { class: classes }, { default: () => h(icon) });
  }

  function renderRouterLink(routeName: string, label: string, classes?: string[]): () => VNode {
    return () =>
      h(
        RouterLink,
        {
          to: { name: routeName },
          class: classes,
        },
        { default: () => label },
      );
  }

  function createIcon(icon: Component, classes?: string[]): VNode {
    return h(NIcon, { class: classes }, { default: () => h(icon) });
  }

  function createLabel(label: string, classes?: string[], clickFn?: () => void): VNode {
    return h('div', { class: classes, onClick: clickFn }, label);
  }

  function createSwitch(label: string, value: boolean, disabled?: boolean): VNode {
    return h(NSwitch, { value, disabled }, { default: () => label });
  }

  function createTag(label: string, type: 'success' | 'error'): VNode {
    return h(NTag, { type }, { default: () => label });
  }

  function createLink(label: string, href: string): VNode {
    return h('a', { href, target: '_blank' }, { default: () => label });
  }

  function createPopover(label: string, trigger: 'hover', placement?: 'top-end', tag?: VNode): VNode {
    return h(
      NPopover,
      { trigger, placement },
      {
        trigger: () => tag,
        default: () => label,
      },
    );
  }

  return { renderIcon, renderRouterLink, createIcon, createLabel, createSwitch, createTag, createLink, createPopover };
}
