import { ref } from 'vue';

export function useConfirmModal() {
  const isConfirming = ref<boolean>(false);

  function startConfirming(): void {
    isConfirming.value = true;
  }

  function finishConfirming(): void {
    isConfirming.value = false;
  }

  function cancel(cancelAction?: () => void): void {
    finishConfirming();
    if (cancelAction !== undefined) {
      cancelAction();
    }
  }

  async function confirm(confirmAction?: () => void | Promise<void>): Promise<void> {
    finishConfirming();
    if (confirmAction !== undefined) {
      await confirmAction();
    }
  }

  return { isConfirming, startConfirming, cancel, confirm };
}
