import { computed } from 'vue';
import { RouteLocationNormalized } from 'vue-router';

import { useUserStore } from '@/store';

export function useAuthorization() {
  const userStore = useUserStore();
  const authNotRequiredRoutes = ['login'];
  const adminAuthRoutes = ['application-list', 'google-hotel-list'];
  const managerAuthRoutes = ['application'];

  const isAuthenticated = computed<boolean>(() => {
    return userStore.isLoggedIn;
  });

  function hasAccess(to: RouteLocationNormalized): boolean {
    const targetRoute = to.name as string;

    if (!isAuthenticated.value) {
      userStore.initUser();
    }

    if (isAuthenticated.value) {
      if (adminAuthRoutes.includes(targetRoute)) {
        return userStore.adminAuthorized;
      } else if (managerAuthRoutes.includes(targetRoute)) {
        return userStore.managerAuthorized;
      } else {
        return !authNotRequiredRoutes.includes(targetRoute);
      }
    } else {
      return authNotRequiredRoutes.includes(targetRoute);
    }
  }

  return { isAuthenticated, hasAccess };
}
