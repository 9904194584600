import dayjs from 'dayjs';

import { ISelectOption } from '@/types/common';
import { GoogleHotelPushType } from '../enums/google-hotel-push-type.enum';
import { IGoogleHotelPushCriteria } from '../interfaces/google-hotel-push-criteria.interface';

export const defaultGoogleHotelPushCriteria: IGoogleHotelPushCriteria = {
  gdsPropertyIds: [],
  from: dayjs().format('YYYY-MM-DD').toString(),
  to: dayjs().add(1, 'year').format('YYYY-MM-DD').toString(),
  sendRates: true,
  sendInventories: true,
};

export const googleHotelPushTypeOptions: ISelectOption[] = [
  { label: '요금 & 재고', value: GoogleHotelPushType.ratesAndInventories },
  { label: '요금', value: GoogleHotelPushType.rates },
  { label: '재고', value: GoogleHotelPushType.inventories },
];
