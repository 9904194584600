import { ISelectOption } from '@/types/common';
import { PropertySearchType } from '../enums/property-search-type.enum';

export const propertySearchOptions: ISelectOption[] = [
  { label: 'GDS ID', value: PropertySearchType.gdsId, isSelected: true },
  { label: 'OSP ID', value: PropertySearchType.ospId },
  // Hide property name search until API is implemented (TODO later)
  // { label: '숙소 이름', value: PropertySearchType.name as string },
  { label: 'URL Path', value: PropertySearchType.urlPath },
];
