import {
  create,
  GlobalThemeOverrides,
  // common
  NButton,
  NCard,
  NCollapse,
  NCollapseItem,
  NDivider,
  NIcon,
  NPageHeader,
  NText,
  NH2,
  NH3,
  NH4,
  NTag,
  // data input
  NForm,
  NFormItem,
  NFormItemGi,
  NInputGroup,
  NInput,
  NInputNumber,
  NSelect,
  NSwitch,
  NCheckbox,
  NCheckboxGroup,
  NDatePicker,
  NTime,
  // data display
  NDataTable,
  // navigation
  NBreadcrumb,
  NBreadcrumbItem,
  NMenu,
  NTabs,
  NTabPane,
  NStep,
  NSteps,
  NPagination,
  // feedback
  NAlert,
  NModal,
  NPopover,
  NMessageProvider,
  NSpin,
  // layout
  NLayout,
  NLayoutSider,
  NLayoutHeader,
  NLayoutContent,
  NGrid,
  NGi,
  NSpace,
  // utility
  // config
  NConfigProvider,
  koKR,
  dateKoKR,
} from 'naive-ui';

import {
  HotelFilled,
  CheckCircleOutlineRound,
  SearchOutlined,
  DeleteOutlined,
  MenuOutlined,
  PersonFilled,
  ExitToAppOutlined,
  HowToRegOutlined,
  PersonAddAltOutlined,
  AdminPanelSettingsFilled,
  ArrowBackOutlined,
  OpenInNewOutlined,
  ErrorOutlineOutlined,
  ErrorOutlined,
  CallMadeOutlined,
  RemoveCircleOutlineRound,
  CancelOutlined,
} from '@vicons/material';

const naive = create({
  components: [
    // common
    NButton,
    NCard,
    NCollapse,
    NCollapseItem,
    NDivider,
    NIcon,
    NPageHeader,
    NText,
    NH2,
    NH3,
    NH4,
    NTag,
    // data input
    NForm,
    NFormItem,
    NFormItemGi,
    NInputGroup,
    NInput,
    NInputNumber,
    NSelect,
    NSwitch,
    NCheckbox,
    NCheckboxGroup,
    NDatePicker,
    NTime,
    // data display
    NDataTable,
    // navigation
    NBreadcrumb,
    NBreadcrumbItem,
    NMenu,
    NTabs,
    NTabPane,
    NStep,
    NSteps,
    NPagination,
    // feedback
    NAlert,
    NModal,
    NPopover,
    NMessageProvider,
    NSpin,
    // layout
    NLayout,
    NLayoutSider,
    NLayoutHeader,
    NLayoutContent,
    NGrid,
    NGi,
    NSpace,
    // utility
    // config
    NConfigProvider,
  ],
});

const themeOverrides: GlobalThemeOverrides = {
  common: {
    // baseColor: '#f1f3f6',
    primaryColor: '#58a9fe',
    primaryColorHover: '#247edc',
    primaryColorPressed: '#0a5aad',
    primaryColorSuppl: '#7c9ab9',
  },
  Layout: {
    color: '#f1f3f6',
    headerColor: '#58a9fe',
  },
};

export {
  naive,
  themeOverrides,
  koKR as locale,
  dateKoKR as dateLocale,
  HotelFilled as HotelIcon,
  CheckCircleOutlineRound as CheckIcon,
  RemoveCircleOutlineRound as RemoveIcon,
  SearchOutlined as SearchIcon,
  DeleteOutlined as DeleteIcon,
  MenuOutlined as MenuIcon,
  PersonFilled as UserIcon,
  ExitToAppOutlined as ExitIcon,
  HowToRegOutlined as RegistIcon,
  PersonAddAltOutlined as PersonAddIcon,
  AdminPanelSettingsFilled as AdminIcon,
  ArrowBackOutlined as BackIcon,
  OpenInNewOutlined as OpenIcon,
  ErrorOutlineOutlined as TipIcon,
  CallMadeOutlined as EnterIcon,
  ErrorOutlined as ErrorIcon,
  CancelOutlined as CancelIcon,
};
