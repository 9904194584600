import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import * as views from '@/views';
import { DefaultLayout } from '@/layouts';

import { useAuthorization } from '@/composables';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: views.Login,
  },
  {
    path: '/',
    name: 'home',
    component: DefaultLayout,
    redirect: '/properties',
    children: [
      { path: '/properties', name: 'property-list', component: views.BookingEnginePropertyList },
      {
        path: '/properties/:id',
        name: 'property-detail',
        component: views.BookingEnginePropertyDetail,
        beforeEnter: (to, from) => {
          if (!to.query.list) {
            let listName = from.name as string;
            if (!['property-list', 'application-list', 'google-hotel-list'].includes(listName)) {
              listName = 'property-list';
            }
            return { ...to, query: { list: listName } };
          }
        },
      },
      {
        path: '/application',
        name: 'application',
        component: views.BookingEngineApplication,
      },
      { path: '/applications', name: 'application-list', component: views.AdminApplicationList },
      { path: '/google-hotels', name: 'google-hotel-list', component: views.AdminGoogleHotelList },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const { isAuthenticated, hasAccess } = useAuthorization();

  if (!hasAccess(to)) {
    return { name: isAuthenticated.value ? 'home' : 'login' };
  }
});

export default router;
