import { ITableColumn } from '@/types/common';

export const propertyTableColumns: ITableColumn[] = [
  { type: 'selection', title: '선택', key: 'selection', className: 'table__col--unclickable' },
  {
    title: 'GH 활성 상태',
    key: 'isLiveOnGoogle',
    className: 'table__col--google-hotels-listing table__col--unclickable',
  },
  { title: 'BE 활성 상태', key: 'property.isEnabled' },
  { title: 'GH 온보딩 날짜', key: 'googleHotelOnboardingRequest.createdAt' },
  { title: 'GDS ID', key: 'gdsId', className: 'table__col--unclickable' },
  { title: 'OSP ID', key: 'ospId', className: 'table__col--unclickable' },
  { title: '숙소 이름', key: 'property.name' },
  { title: '사용 제품', key: 'property.product' },
  { title: '공급사', key: 'property.vendor.name' },
  { title: 'URL Path', key: 'settings.urlPath', className: 'table__col--url-path table__col--unclickable' },
  { title: 'BE 온보딩 상태', key: 'bookingEngineOnboardingStatus' },
  { title: 'GH 온보딩 상태', key: 'googleHotelOnboardingRequest.status' },
  { title: '상태 내용', key: 'googleHotelOnboardingRequest.errorType' },
  { title: '구글 지도', key: 'googleMapsMatchStatus' },
];
