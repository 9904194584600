import { computed, ref, watch } from 'vue';
import { useMessage } from 'naive-ui';

import { BookingEngineOnboardingStatus, PropertyFilterType } from '@/types/booking-engine-property';
import {
  defaultPageSize,
  defaultPropertySearchInput,
  IFilter,
  IPropertyQueryCriteria,
  IPropertySearchInput,
} from '@/types/common';

import { useApi } from './api.composable';
import { usePagination } from './pagination.composable';
import { GoogleHotelOnboardingErrorType, GoogleHotelOnboardingStatus } from '@/types/google-hotel';

const message = useMessage();
const { data, error, fetchData } = useApi();
const { pagination, changePageSize, resetPage, setTotal } = usePagination();

const listName = ref<string>('');
const listFilters = ref<IFilter[]>([]);
const searchInput = ref<IPropertySearchInput>({ ...defaultPropertySearchInput });

export function usePropertyListQuery(fetchUrl: string, filters: IFilter[]) {
  watch(
    () => pagination.value.page,
    async () => {
      await fetchList();
    },
  );

  // filter
  const isFiltering = ref<boolean>(false);

  async function setFilters(pageSize: number, filters: IFilter[]): Promise<void> {
    isFiltering.value = true;

    listFilters.value = filters;
    changePageSize(pageSize);

    await fetchList();
  }

  async function resetFilters(): Promise<void> {
    isFiltering.value = true;

    listFilters.value = filters;
    changePageSize(defaultPageSize);

    await fetchList();
  }

  //search
  const isSearching = ref<boolean>(false);

  async function setSearchInput(input: IPropertySearchInput): Promise<void> {
    isSearching.value = true;

    searchInput.value = input;

    await fetchList();
  }

  async function resetPropertyList(list: string): Promise<void> {
    listName.value = list;
    listFilters.value = [...filters];
    searchInput.value = { ...defaultPropertySearchInput };
    changePageSize(defaultPageSize);
    resetPage();

    await fetchList();
  }

  const isFetching = ref<boolean>(false);

  const queryCriteria = computed<IPropertyQueryCriteria>(() => {
    return listFilters.value.reduce(
      (criteria, f) => {
        const selectedOptions = f.options.filter((o) => o.isSelected);
        switch (f.key) {
          case PropertyFilterType.bookingEngineOnboardingStatus:
            return {
              ...criteria,
              beOnboardingStatus:
                selectedOptions.length < f.options.length
                  ? selectedOptions.map((o) => o.value as BookingEngineOnboardingStatus)
                  : undefined,
            };
          case PropertyFilterType.googleHotelOnboardingStatus:
            return {
              ...criteria,
              ghOnboardingStatus:
                selectedOptions.length < f.options.length
                  ? selectedOptions.map((o) => o.value as GoogleHotelOnboardingStatus | null)
                  : undefined,
            };
          case PropertyFilterType.googleHotelOnboardingErrorType:
            return {
              ...criteria,
              ghErrorType:
                selectedOptions.length < f.options.length
                  ? selectedOptions.map((o) => o.value as GoogleHotelOnboardingErrorType | null)
                  : undefined,
            };
          default:
            return { ...criteria };
        }
      },
      {
        searchType: searchInput.value.type && searchInput.value.keyword ? searchInput.value.type : undefined,
        keyword: searchInput.value.type && searchInput.value.keyword ? searchInput.value.keyword : undefined,
      } as IPropertyQueryCriteria,
    );
  });

  async function fetchList(): Promise<void> {
    if (isFetching.value) {
      return;
    }

    isFetching.value = true;
    await fetchData(`${fetchUrl}`, {
      ...queryCriteria.value,
      limit: pagination.value.pageSize,
      page: pagination.value.page,
    });

    if (error.value) {
      message.error('목록 데이터를 불러오는 중 에러가 발생했습니다.');
    } else {
      setTotal(data.value.total);
    }

    finishFetching();
  }

  function finishFetching(): void {
    isFetching.value = false;
    isFiltering.value = false;
    isSearching.value = false;
  }

  return {
    listName,
    pagination,
    listFilters,
    isFiltering,
    setFilters,
    resetFilters,
    searchInput,
    isSearching,
    setSearchInput,
    resetPropertyList,
    isFetching,
    listData: data,
  };
}
