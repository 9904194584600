import { ref } from 'vue';
import axios, { AxiosError } from 'axios';
import { IError } from '@/types/common';

export function useApi() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const apiData = ref<any | null>(null);
  const error = ref<IError | null>(null);

  function resetData(): void {
    apiData.value = null;
    error.value = null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function fetchData(url: string, params?: Record<string, any>): Promise<void> {
    resetData();
    try {
      const searchParams = new URLSearchParams();
      if (params) {
        for (const key of Object.keys(params)) {
          const val: string | string[] = params[key];
          if (val) {
            if (Array.isArray(val)) {
              val.forEach((v) => {
                searchParams.append(key, v);
              });
            } else {
              searchParams.append(key, val);
            }
          }
        }
      }
      const { data } = await axios.get(url, { params: searchParams });
      apiData.value = data;
    } catch (err) {
      error.value = (err as AxiosError).response?.data as IError;
    }
  }

  async function insertData(url: string, inputData: object): Promise<void> {
    resetData();
    try {
      const { data } = await axios.post(url, inputData);
      apiData.value = data;
    } catch (err) {
      error.value = (err as AxiosError).response?.data as IError;
    }
  }

  async function updateData(url: string, inputData: object): Promise<void> {
    resetData();
    try {
      const { data } = await axios.put(url, inputData);
      apiData.value = data;
    } catch (err) {
      error.value = (err as AxiosError).response?.data as IError;
    }
  }

  async function deleteData(url: string): Promise<void> {
    resetData();
    try {
      const { data } = await axios.delete(url);
      apiData.value = data;
    } catch (err) {
      error.value = (err as AxiosError).response?.data as IError;
    }
  }

  return { data: apiData, error, fetchData, insertData, updateData, deleteData };
}
