import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import { pinia } from './store';

import axiosPlugin from './plugins/axios';
import dayjsPlugin from './plugins/dayjs';
import filtersPlugin from './plugins/filters';
import { naive } from './plugins/naive-ui';

createApp(App).use(pinia).use(router).use(axiosPlugin).use(dayjsPlugin).use(filtersPlugin).use(naive).mount('#app');
