import { ISelectOption } from '@/types/common';

import { PropertyCountry } from '../enums/property-country.enum';

export const propertyCountryOptions: ISelectOption[] = [
  { label: `캐나다 | ${PropertyCountry.CA}`, value: PropertyCountry.CA },
  { label: `중국 | ${PropertyCountry.CN}`, value: PropertyCountry.CN },
  { label: `홍콩 | ${PropertyCountry.HK}`, value: PropertyCountry.HK },
  { label: `인도네시아 | ${PropertyCountry.ID}`, value: PropertyCountry.ID },
  { label: `일본 | ${PropertyCountry.JP}`, value: PropertyCountry.JP },
  { label: `한국 | ${PropertyCountry.KR}`, value: PropertyCountry.KR },
  { label: `말레이시아 | ${PropertyCountry.MY}`, value: PropertyCountry.MY },
  { label: `필리핀 | ${PropertyCountry.PH}`, value: PropertyCountry.PH },
  { label: `싱가포르 | ${PropertyCountry.SG}`, value: PropertyCountry.SG },
  { label: `태국 | ${PropertyCountry.TH}`, value: PropertyCountry.TH },
  { label: `대만 | ${PropertyCountry.TW}`, value: PropertyCountry.TW },
  { label: `미국 | ${PropertyCountry.US}`, value: PropertyCountry.US },
  { label: `베트남 | ${PropertyCountry.VN}`, value: PropertyCountry.VN },
];
