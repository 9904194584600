import { BookingEngineTheme } from '../enums/booking-engine-theme.enum';
import { IBookingEngineThemeOption } from '../interfaces/booking-engine-theme.interface';

export const bookingEngineThemeOptions: IBookingEngineThemeOption[] = [
  {
    label: BookingEngineTheme.white,
    value: '#111111',
  },
  {
    label: BookingEngineTheme.darkGray,
    value: '#616161',
  },
  {
    label: BookingEngineTheme.brick,
    value: '#9F0000',
  },
  {
    label: BookingEngineTheme.brown,
    value: '#74564A',
  },
  {
    label: BookingEngineTheme.beige,
    value: '#BCA07F',
  },
  {
    label: BookingEngineTheme.mustard,
    value: '#E4B12A',
  },
  {
    label: BookingEngineTheme.green,
    value: '#007D58',
  },
  {
    label: BookingEngineTheme.ondaBlue,
    value: '#58A9FE',
  },
  {
    label: BookingEngineTheme.midnight,
    value: '#2D5286',
  },
];
